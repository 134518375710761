import en from './en'
import ru from './ru'

const locales = [
  'en',
  'ru'
]
const messages = {
  en,
  ru
}

export default {
  locales,
  messages
}
